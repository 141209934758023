<template>
  <div class="home-container">
    <!-- 搜索 + 轮播信息 -->
    <div class="home-top" style="padding-bottom: 1.125rem">
      <header class="banner"></header>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},

  computed: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
  filters: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.home-container {
  position: relative;
}
.home-top {
  position: relative;
  background-color: transparent;
}

.banner {
  width: 100%;
  height: 11.25rem;
  background-image: url("~@/assets/deyangImg/newbanner.png");
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
